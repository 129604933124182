/**
 * Main navigation
 */

import { Back, gsap, Power2, Power4 } from 'gsap'

/**
 * Component settings
 */
let mainNavigationComponent = {
    element: false,
    open: false,
    timeline: false,
    timelineDuration: 5,
    layer: false,
    button: false,
    links: false,
    wave: false,
    path: false,

    /**
   * Component setup
   */
    setup() {
        this.element = document.getElementById('main-navigation')

        if (this.element && this.element instanceof HTMLElement) {
            this.initialize()
        }
    },

    /**
   * Initialize component
   */
    initialize() {
        this.layer = this.element.querySelector('.main-navigation--inner')
        this.button = this.element.querySelector('.toggle-nav')
        this.overlay = this.element.querySelector('.overlay')
        this.links = this.element.querySelectorAll('li')
        this.menu = this.element.querySelectorAll('li a')
        this.wave = this.element.querySelector('.wave path')
        this.homeLink = this.element.querySelector('.anchor-home')

        if (this.layer && this.layer instanceof HTMLElement) {
            gsap.set(
                this.layer,
                { xPercent: -110 }
            )

            gsap.set(
                this.links,
                {
                    autoAlpha: 0,
                    x: -50
                }
            )

            this.registerTimeline()
            this.handle()
        }
    },

    /**
   * Register timeline
   */
    registerTimeline() {
    // Init timeline
        this.timeline = gsap.timeline(
            {
                yoyo: true,
                smoothChildTiming: true
            }
        )

        // Register timeline tweens
        // & force reversed state on start
        this.timeline
            .addLabel('start')
            .fromTo(
                this.overlay,
                1,
                { autoAlpha: 0 },
                {
                    autoAlpha: 1,
                    ease: Power2.easeInOut
                }
            )
            .fromTo(
                this.layer,
                1,
                { xPercent: -115 },
                {
                    xPercent: 0,
                    ease: Power2.easeInOut
                },
                'start'
            )
            .to(
                this.links,
                0.4,
                {
                    x: 0,
                    autoAlpha: 1,
                    ease: Back.easeOut.config(0.8),
                    stagger: 0.1
                },
                '-=0.5'
            )
            .to(
                this.wave,
                1,
                {
                    attr: { d: 'M0,0 L10,0 C12.71,38.1529 38,74.8524 38,113.732 C38,154.61 10.181,187.313 10,235.458 C10.181,275.791 38,314.307 38,355.185 C38,396.245 10.181,429.129 10,477.456 C10,517.789 38,556.487 38,597.547 C38,638.1964 10.5572,670.832502 10.008344,718.549394 L10,720 L0,720 L0,0 L10,0 L0,0 Z' },
                    ease: Power4.easeOut
                },
                '-=1'
            )
            .reverse()
    },

    /**
   * Handle events
   */
    handle() {
        this.button.addEventListener(
            'click',
            () => this.toggleNavigation()
        )

        this.overlay.addEventListener(
            'click',
            () => this.hideNavigation()
        )

        if (this.homeLink && this.homeLink instanceof HTMLAnchorElement) {
            this.homeLink.addEventListener(
                'click',
                event => {
                    event.preventDefault()

                    gsap.to(
                        window,
                        {
                            callbackScope: this,
                            onStart() {
                                this.hideNavigation()
                            },
                            duration: 0.8,
                            scrollTo: 0,
                            ease: Power4.easeInOut
                        }
                    )
                }
            )
        }
    },

    /**
   * Toggle navigation
   */
    toggleNavigation() {
    // Toggle body classname
        document.body.classList.toggle('menu-visible')

        // Toggle timeline
        this.timeline.reversed(!this.timeline.reversed())
    },

    /**
   * Hide navigation
   */
    hideNavigation(navigation = false) {
    // Remove body classname
        document.body.classList.remove('menu-visible')

        // Add callback
        if (navigation && navigation.indexOf('#') === -1) {
            this.timeline.eventCallback(
                'onReverseComplete',
                () => {
                    console.log('change location')
                    window.location.href = navigation
                }
            )
        }

        // Reverse timeline
        this.timeline.reverse()
    }
}

/**
 * Export component
 */
export default mainNavigationComponent
