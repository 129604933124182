/**
 * Header
 */

import { gsap, Power4 } from 'gsap'
import inView from 'in-view'

let headerComponent = {
    /**
   * Set up header component
   */
    setup() {
        this.element = document.querySelector('.main-header')
        this.brand = document.querySelector('.main-logo.is-anchor')

        if (this.element && this.element instanceof HTMLElement && document.body.classList.contains('pages-homepage')) {
            inView('.toggle-header-bg')
                .on(
                    'enter',
                    () => {
                        this.toggleHeaderBackground()
                    }
                )
                .on(
                    'exit',
                    () => {
                        this.toggleHeaderBackground(true)
                    }
                )
        }

        this.setHandlers()
    },

    /**
   * Toggle header background
   * @param {Boolean} active
   */
    toggleHeaderBackground(active = false) {
        if (active) {
            this.element.classList.add('with-bg')
        } else {
            this.element.classList.remove('with-bg')
        }
    },

    /**
   * Setup event handlers
   */
    setHandlers() {
        if (this.brand && this.brand instanceof HTMLAnchorElement) {
            this.brand.addEventListener(
                'click',
                event => {
                    let targetElement = document.getElementById('emblem')

                    event.preventDefault()

                    if (targetElement instanceof HTMLElement) {
                        gsap.to(
                            window,
                            {
                                duration: 0.8,
                                scrollTo: targetElement,
                                ease: Power4.easeInOut
                            }
                        )
                    }
                }
            )
        }
    }
}

export default headerComponent
