/**
 * Image lazy load
 */

import inView from 'in-view'

let lazyloadComponent = {
    /**
   * Setup lazy load
   * As soon as elements get in viewport launch lazyload process
   */
    setup() {
        inView('.lazyload').on('enter', this.process)
        inView('.lazyload').check()
    },

    /**
   * Process element
   * Switch attributes & set classname when media loaded
   */
    process: element => {
        let media = element.querySelector('img')

        if (media && media instanceof HTMLImageElement && !element.classList.contains('ready')) {
            media.addEventListener(
                'load',
                () => {
                    element.classList.add('ready')
                }
            )

            media.setAttribute('src', media.dataset.src)
            media.removeAttribute('data-src')
        }
    }
}

export default lazyloadComponent
