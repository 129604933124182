/**
 * Coupe transversal
 */

let coupeTransversalComponent = {
    /**
   * Set up component
   */
    setup() {
        this.element = document.querySelector('.coupe-transversal')

        if (this.element && this.element instanceof HTMLElement) {
            this.desktopSvg = this.element.querySelector('.coupe-desktop')
            this.setHandlers()
        }
    },

    /**
   * Set handlers
   */
    setHandlers() {
        let previousClass = false
        let hoverable = this.desktopSvg.querySelectorAll('.stage, a')

        _.each(
            hoverable,
            stage => {
                stage.addEventListener(
                    'mouseover',
                    () => {
                        if (previousClass) {
                            this.desktopSvg.classList.remove(previousClass)
                        }

                        previousClass = 'stage-' + stage.dataset.stage

                        this.desktopSvg.classList.add('hovered')
                        this.desktopSvg.classList.add(previousClass)
                        this.desktopSvg.querySelector('.level-' + stage.dataset.stage).classList.remove('stage-level')
                    }
                )

                stage.addEventListener(
                    'mouseout',
                    () => {
                        if (previousClass) {
                            this.desktopSvg.classList.remove(previousClass)
                        }

                        this.desktopSvg.classList.remove('hovered')
                        this.desktopSvg.querySelector('.level-' + stage.dataset.stage).classList.add('stage-level')
                    }
                )

                if (stage.classList.contains('stage-level')) {
                    stage.addEventListener(
                        'click',
                        () => {
                            window.location.href = stage.dataset.uri
                        }
                    )
                }
            }
        )
    }
}

export default coupeTransversalComponent
