/**
 * RX Slider
 */

import { emit, on } from 'jetemit'
import SwiperSlider from 'swiperslider'

/**
 * Component settings
 */
let rxSliderComponent = {
    /**
   * Setup component
   */
    setup() {
        this.active = 0
        this.zoom = false
        this.sliders = []
        this.gallery = document.querySelector('.gallery')
        this.currentTheme = false

        if (this.gallery && this.gallery instanceof HTMLElement) {
            this.sliderElements = this.gallery.querySelectorAll('.swiper-container')
            this.tabs = this.gallery.querySelectorAll('.gallery-tabs button')

            this.buildSliders()
            this.attachHandlers()
        }
    },

    /**
   * Build sliders
   */
    buildSliders() {
    // create component refs
        let base = this

        // Build swiper sliders
        _.each(
            this.sliderElements,
            slider => {
                let swiperSlider = new SwiperSlider(
                    slider,
                    {
                        loop: false,
                        grabCursor: true,
                        pagination: {
                            el: slider.querySelector('.swiper-pagination'),
                            clickable: true
                        },
                        navigation: {
                            nextEl: slider.querySelector('.swiper-button-next'),
                            prevEl: slider.querySelector('.swiper-button-prev')
                        },
                        slidesPerView: 1,
                        on: {
                            slideChange() {
                                let active = this.activeIndex

                                base.updateThemeColor(this.slides[active].dataset.theme)
                            }
                        }
                    }
                )

                if (swiperSlider.slides.length <= 1) {
                    slider.classList.add('single')
                }

                this.sliders.push(swiperSlider)
            }
        )

        // Initialize theme color
        this.initializeThemeColor()
    },

    /**
   * Initialize theme color
   */
    initializeThemeColor() {
        let activeSliders = this.sliders[this.active]
        let activeSlide = activeSliders.activeIndex

        this.updateThemeColor(activeSliders.slides[activeSlide].dataset.theme)
    },

    /**
   * Update theme color
   * @param {String|Undefined} name
   */
    updateThemeColor(name) {
        if (this.currentTheme && this.currentTheme !== name) {
            this.gallery.classList.remove(this.currentTheme)
        }

        if (name) {
            this.gallery.classList.add(name)
            this.currentTheme = name
        }
    },

    /**
   * Attach event handlers
   */
    attachHandlers() {
    // Listen active changes
        on(
            'toggle-gallery',
            () => {
                this.updateActiveGallery()
                this.initializeThemeColor()
            }
        )

        // Tab switcher
        _.each(
            this.tabs,
            (tab, index) => {
                tab.addEventListener(
                    'click',
                    () => {
                        if (index !== this.active) {
                            this.active = index
                            emit('toggle-gallery')
                        }
                    }
                )
            }
        )

        // Gallery zoom close button
        this.gallery.querySelector('.hide').addEventListener(
            'click',
            () => {
                document.body.classList.remove('gallery-zoom')
                this.gallery.classList.remove('description-hidden')
                this.zoom = false
                this.updateActiveSlider()
            }
        )

        // Trigger zoom
        _.each(
            this.gallery.querySelectorAll('.media'),
            zoom => {
                zoom.addEventListener(
                    'click',
                    () => {
                        if (this.zoom) {
                            this.gallery.classList.toggle('description-hidden')
                        } else {
                            document.body.classList.add('gallery-zoom')
                            this.zoom = true
                            this.updateActiveSlider()
                        }
                    }
                )
            }
        )
    },

    /**
   * Update active gallery
   */
    updateActiveGallery() {
        let activeSlider = this.sliders[this.active]

        if (activeSlider) {
            _.each(
                this.tabs,
                (tab, index) => {
                    if (index === this.active) {
                        tab.classList.add('active')
                    } else {
                        tab.classList.remove('active')
                    }
                }
            )

            _.each(
                this.sliderElements,
                (slider, index) => {
                    if (index === this.active) {
                        slider.classList.add('active')
                    } else {
                        slider.classList.remove('active')
                    }
                }
            )

            this.updateActiveSlider()
        }
    },

    /**
   * Update active slider
   */
    updateActiveSlider() {
        let activeSlider = this.sliders[this.active]

        activeSlider.update()
    }
}

/**
 * Export component
 */
export default rxSliderComponent
