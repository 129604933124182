/**
 * Application main javascript
 */

import { Back, gsap, Power3, Power4 } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import inView from 'in-view'
import { emit } from 'jetemit'
import Modernizr from 'modernizr'
import coupeTransversalComponent from '~/components/coupe-transversal'
import headerComponent from '~/components/header'
import cookiesComponent from '~/components/cookies'
import lazyloadComponent from '~/components/lazyload'
import mainNavigationComponent from '~/components/navigation'
import rxSliderComponent from '~/components/rx-slider'
import ImageCompare from 'image-compare-viewer'
import { APP, EVENTS } from '~/config/constant'

console.log('**** %s ****', APP.NAME.toUpperCase())

/**
 * Register gsap plugins
 * Prevent webpack tree shacking
 */
gsap.registerPlugin(ScrollToPlugin)

let application = {
    /**
   * Initialize components
   */
    initComponents() {
    // Set up components
        headerComponent.setup()
        mainNavigationComponent.setup()
        lazyloadComponent.setup()
        coupeTransversalComponent.setup()
        rxSliderComponent.setup()
        cookiesComponent.setup()
        ImageCompare
    },

    /**
   * Start app
   */
    start() {
        this.splash = document.getElementById('splash')

        // Start handling in views
        this.handleInView()
        this.imageCompareView()

        if (this.splash && this.splash instanceof HTMLElement) {
            let splashTimeline = gsap.timeline(
                {
                    paused: true,
                    callbackScope: this,
                    onComplete: this.ready
                }
            )

            splashTimeline
                .addLabel('start')
                .to(
                    this.splash,
                    1,
                    {
                        xPercent: -115,
                        ease: Power4.easeInOut
                    }
                )
                .to(
                    this.splash.querySelector('.wave path'),
                    1,
                    {
                        attr: { d: 'M0,0 L1.31578947,0 C1.67236842,38.1529 5,74.8524 5,113.732 C5,154.61 1.33960526,187.313 1.31578947,235.458 C1.33960526,275.791 5,314.307 5,355.185 C5,396.245 1.33960526,429.129 1.31578947,477.456 C1.31578947,517.789 5,556.487 5,597.547 C5,638.1964 1.38910526,670.832502 1.31688737,718.549394 L1.31578947,720 L0,720 L0,0 L1.31578947,0 L0,0 Z' },
                        ease: Power3.easeInOut
                    },
                    'start'
                )
                .fromTo(
                    document.getElementById('home-image'),
                    1.5,
                    { y: 600 },
                    {
                        y: 0,
                        ease: Back.easeOut.config(0.8)
                    },
                    'start'
                )

            splashTimeline.duration(2)
            splashTimeline.play()
        } else {
            this.ready()
        }
    },

    /**
   * Application ready
   * Dispatch ready event
   * Then add app-ready classname to body element
   */
    ready() {
        emit(EVENTS.READY)
        document.body.classList.add('app-ready')
    },

    /**
   * Handle inview detection
   */
    handleInView() {
        inView('.inview').on(
            'enter',
            element => {
                element.classList.add('inview-active')
            }
        )

        inView('.inview').check()
    },

    /**
     * Image compare
     *
     */
    imageCompareView() {
        const element = document.getElementById('image-spliter')
        const options = {

            // UI Theme Defaults

            controlColor: '#004563',
            controlShadow: false,
            addCircle: true,
            addCircleBlur: true,

            // Label Defaults

            showLabels: false,
            labelOptions: {
                before: 'Before',
                after: 'After',
                onHover: false
            },

            // Smoothing

            smoothing: true,
            smoothingAmount: 100,

            // Other options

            hoverStart: false,
            verticalMode: false,
            startingPoint: 0,
            fluidMode: false
        }

        if (document.querySelector('.pages-bos')) {
            new ImageCompare(element, options).mount()
        }
    }
}

/**
 * DOM Load
 */
window.onload = () => {
    application.initComponents()
    application.start()
}

/**
 * Scroll restoration
 */
if (!window.location.hash) {
    if (Modernizr.history && 'scrollRestoration' in window.history) {
        history.scrollRestoration = 'manual'
    } else {
        window.scrollTo(0, 0)
    }
}

/**
 * Hot reload
 */
if (module.hot) {
    module.hot.accept()
}
